<script>
import { mapGetters, mapState, mapActions } from "vuex";
import AppTooltip from "@/features/bootstrap/Tooltip";

export default {
  name: "AppHeaderProduct",

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(["isMobile", "langs"]),
    ...mapState("Orders", {
      order: (state) => state.order.model,
    }),

    /**
     * Retorna classes diferentes se for acessado por uma plataforma mobile ou desktop
     * @return {string}
     */
    mobileHeader() {
      return this.isMobile
        ? "app__product-rating__header-isMobile"
        : "app__product-rating__header";
    },

    /**
     * Retorna qual a cor do status como string
     * @return {string}
     */
    statusColor() {
      const color =
        this.$route.name === "orders.rating"
          ? "closed"
          : this.order.status.type;

      return color;
    },

    /**
     * Define estilo do badge
     * @return {object}
     */
    styleStatusBadge() {
      if (this.loading) {
        return "";
      }

      return {
        backgroundColor: this.order.status.background_color,
        color: this.order.status.font_color,
      };
    },

    /**
     * Define o texto do badge
     * @return {string}
     */
    badgeText() {
      return this.order.status.display_name
        ? this.order.status.display_name
        : this.order.status.store;
    },

    /**
     * Verifica se existe o texto do badge ou o texto do tooltip
     * @return {bool}
     */
    hasBadgeOrTooltip() {
      return this.badgeText || this.order.status.description;
    },

    /**
     * Pega o hash do pedido pelo parametro da URL
     * @return {string|null}
     */
    orderIdHash() {
      return this.$route?.params?.idOrder;
    },
  },

  methods: {
    ...mapActions("Orders", ["clearMessages"]),

    /**
     * Limpa as mensagens e volta a tela do pedido
     * @return {undefined}
     */
    backToOrder() {
      this.clearMessages();
      this.$router.back();
    },
  },

  components: {
    AppTooltip,
  },
};
</script>

<template>
  <section :class="mobileHeader">
    <div class="app__product-rating__header-left">
      <div
        class="app__product-rating__header-rectangle"
        :class="`app__product-rating__header-rectangle--${statusColor} ${
          loading && 'app__loading'
        }`"
      />
      <div class="app__product-rating__header-id-container">
        <p
          class="app__product-rating__header-id-title"
          :class="{ app__loading: loading }"
        >
          {{ order.status.generic }}
        </p>
        <p
          class="app__product-rating__header-id"
          :class="`app__product-rating__header-id--${statusColor}`"
        >
          <router-link
            :to="`/orders/${this.orderIdHash}`"
            :class="{ app__loading: loading }"
          >
            #{{ this.order.id }}
          </router-link>
        </p>
        <app-tooltip
          v-show="hasBadgeOrTooltip"
          class="app__order-list__order-status-store__tooltip"
          :tooltipMessage="order.status.description"
          customClass="delivery-tooltip"
        >
          <span
            class="app__order-list__order-status-store badge"
            id="status-store__badge"
            :style="styleStatusBadge"
            :class="{ app__loading: loading }"
          >
            {{ badgeText }}
          </span>
        </app-tooltip>
      </div>
    </div>
    <div class="app__product-rating__header-right">
      <span @click="this.backToOrder()" :class="{ app__loading: loading }">
        {{ this.langs.product["back"] }}
      </span>
    </div>
  </section>
</template>
