<script>
import AppIcon from "@/features/general/Icons";
import AppHeaderProduct from "./header-product";
import AppStars from "./stars";
import AppProductInfo from "./product-info";
import { mapGetters, mapActions, mapState } from "vuex";
import AppModal from "@/domains/WaitingList/components/modal";
import AppSvg from "@/features/general/Svg";
import AppAlert from "@/features/bootstrap/Alert";
import { Modal } from "bootstrap";

export default {
  name: "AppProductRating",

  data() {
    return {
      page: "add",
      disableButton: true,
      isSaving: false,
      isLoading: false,
      dataModal: {
        id: 0,
        title: "",
        description: "",
        buttons: [
          {
            text: "",
            classes: "",
            function: () => {},
          },
        ],
      },
      primaryButton: {
        text: "",
        action: () => {},
      },
      secondaryButton: {
        text: "",
        action: () => {},
      },
    };
  },

  mounted() {
    this.isLoading = true;
    this.clearMessages();
    this.getProduct(this.$route.params.idComment);
  },

  components: {
    AppIcon,
    AppHeaderProduct,
    AppStars,
    AppProductInfo,
    AppModal,
    AppSvg,
    AppAlert,
  },

  methods: {
    ...mapActions("Orders", [
      "setMessage",
      "clearMessages",
      "setProductRating",
      "setProductRatingStars",
      "setProductRatingComment",
      "deleteProductRating",
    ]),

    /**
     * Faz a requisição do produto pelo hash do comentário
     * @param {string} hash
     * @return {undefined}
     */
    getProduct(hash) {
      if (hash === "new") return this.setPage();

      const path = `product-comments/${hash}`;
      this.$http
        .get(path)
        .then(async (response) => {
          this.setProductRating(response.data);
          await this.setPage();
          this.isLoading = false;
        })
        .catch(() => {
          this.setMessage({
            text: this.langs.product["failed-find-product"],
            variant: "danger",
          });
          this.$router.push(`/orders/${this.$route.params.idOrder}`);
        });
    },

    /**
     * Exclui a avaliação
     * @return {undefined}
     */
    deleteRating() {
      this.isSaving = true;
      this.clearMessages();
      const path = `product-comments/${this.$route.params.idComment}`;

      this.$http
        .delete(path)
        .then(() => {
          this.deleteProductRating();
          this.setMessage({
            text: this.langs.product["deleted-review"],
            variant: "success",
          });
          this.isSaving = false;
          this.$router.push(`/orders/${this.$route.params.idOrder}`);
        })
        .catch(() => {
          this.setMessage({
            text: this.langs.product["failed-delete-rating"],
            variant: "danger",
          });
          this.isSaving = false;
        });
    },

    /**
     * Altera a avaliação ja existente
     * @return {undefined}
     */
    updateRating() {
      this.isSaving = true;
      this.clearMessages();
      const path = `product-comments/${this.$route.params.idComment}`;
      const body = {
        order_id: this.$route.params.idOrder,
        product_id: this.product.id,
        rating: this.product.ProductComment.rating,
        comment: this.product.ProductComment.comment,
        show_email: 0,
      };

      this.$http
        .put(path, body)
        .then(() => {
          this.setMessage({
            text: this.langs.product["edit-success"],
            variant: "success",
          });
          this.isSaving = false;
          this.$router.push(`/orders/${this.$route.params.idOrder}`);
        })
        .catch(() => {
          this.setMessage({
            text: this.langs.product["failed-send-new-rating"],
            variant: "danger",
          });
          this.isSaving = false;
        });
    },

    /**
     * Cria uma nova avaliação
     * @return {undefined}
     */
    createRating() {
      this.clearMessages();
      this.isSaving = true;
      const oldProductName = this.product.name;
      const path = `product-comments`;
      const body = {
        order_id: this.$route.params.idOrder,
        product_id: this.product.product_id,
        rating: this.product.ProductComment.rating,
        comment: this.product.ProductComment.comment,
        show_email: 0,
      };

      this.$http
        .post(path, body)
        .then(() => {
          this.setMessage({
            text: `Produto <b>${oldProductName}</b> avaliado com sucesso!`,
            variant: "success",
          });
          this.setDataModal("thanks");
          this.isSaving = false;
        })
        .catch(() => {
          this.setMessage({
            text: this.langs.product["failed-create-new-rating"],
            variant: "danger",
          });
          this.isSaving = false;
        });
    },

    /**
     * Verifica se há mais produtos para serem avaliados
     * @return {undefined}
     */
    rateMoreProducts() {
      let productsToRating = [];
      const orderPath = `/orders/${this.$route.params.idOrder}`;
      this.isLoading = true;

      this.$http
        .get(orderPath)
        .then((response) => {
          this.isLoading = false;
          this.disableButton = true;

          response.data.products.map((product) => {
            if (
              product?.ProductComment?.rating === undefined ||
              product?.ProductComment?.rating === "0"
            ) {
              productsToRating.push(product);
            }
          });

          if (productsToRating.length > 0) {
            this.setProductRating(productsToRating[0]);
          } else {
            this.$router.push(`/orders/${this.$route.params.idOrder}`);
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.clearMessages();
          this.setMessage({
            text: this.langs.product["failed-find-more-products-for-review"],
            variant: "danger",
          });
          this.$router.push(`/orders/${this.$route.params.idOrder}`);
        });
    },

    /**
     * Define o tipo da página, podendo ser:
     * adição de comentário
     * edição de comentário
     * vizualização do comentário
     * @return {undefined}
     */
    setPage() {
      if (!this.product.id) {
        this.$router.back();
      }
      const { status } = this.product.ProductComment;
      this.page = {
        0: "edit",
        1: "view",
        undefined: "add",
      }[status];

      this.isLoading = false;
      this.setPrimaryButton();
      this.setSecondaryButton();
    },

    /**
     * Atualiza o valor da estrela
     * @param {string} rating
     * @return {undefined}
     */
    setStars(rating) {
      this.setProductRatingStars(rating);
      this.checkStarValue();
    },

    /**
     * Atualiza o comentário
     * @return {undefined}
     */
    setComment(e) {
      this.setProductRatingComment(e.target.value);
      this.checkStarValue();
    },

    /**
     * Função chamada quando o formulário é enviado
     * @return {undefined}
     */
    onSubmit() {
      if (this.page === "edit") {
        return this.updateRating();
      }

      if (this.product.ProductComment.comment.length < 1) {
        this.setDataModal("no-comment");
      } else {
        this.createRating();
      }
    },

    /**
     * Atribui valores que serão usados no modal
     * @return {object}
     */
    setDataModal(modal) {
      const ratingModal = new Modal(document.getElementById("exampleModal"));
      ratingModal.show();

      const modais = {
        thanks: {
          id: this.product.product_id,
          title: this.langs.product["thank-you"],
          description: `<p>${this.langs.product["precious-evaluation"]}</p>`,
          buttons: [
            {
              classes:
                "app__button app__button--primary app__button--no-margin app__waiting-list__button--second",
              text: this.langs.product["continue-rating"],
              function: this.rateMoreProducts,
            },
          ],
        },
        "no-comment": {
          id: this.product.product_id,
          title: this.langs.product["important-opinion"],
          description: `<p>${this.langs.product["question-about-adding-review"]}</p>`,
          buttons: [
            {
              classes:
                "app__button app__button--primary-outline app__button--no-margin app__waiting-list__button--second",
              text: this.langs.product["add-a-comment"],
              function: this.focusInputComment,
            },
            {
              classes:
                "app__button app__button--primary app__button--no-margin app__waiting-list__button--second",
              text: this.langs.product["submit-review"],
              function: this.createRating,
            },
          ],
        },
        delete: {
          title: this.langs.product["question-delete-review"],
          description: `<p>${this.langs.product["question-about-delete-review"]}</p>`,
          buttons: [
            {
              classes:
                "app__button app__button--primary-outline app__button--no-margin app__waiting-list__button--second",
              text: this.langs.product["cancel"],
            },
            {
              classes:
                "app__button app__button--primary app__button--no-margin app__waiting-list__button--second btn-danger",
              text: this.langs.product["delete"],
              function: this.deleteRating,
            },
          ],
        },
      };

      return (this.dataModal = modais[modal]);
    },

    /**
     * Atribui valores que serão usados no botão primario
     * @return {object}
     */
    setPrimaryButton() {
      const buttons = {
        add: {
          text: this.langs.product["submit-review"],
          action: () => {},
        },
        edit: {
          text: this.langs.product["save_editing"],
          action: () => {},
        },
        view: {
          text: "",
          action: () => {},
        },
      };

      return (this.primaryButton = buttons[this.page]);
    },

    /**
     * Atribui valores que serão usados no botão secundario
     * @return {object}
     */
    setSecondaryButton() {
      const buttons = {
        add: {
          text: this.langs.product["cancel"],
          action: () => this.backToOrder(),
        },
        edit: {
          text: this.langs.product["back"],
          action: () => this.backToOrder(),
        },
        view: {
          text: this.langs.product["back"],
          action: () => this.backToOrder(),
        },
      };
      return (this.secondaryButton = buttons[this.page]);
    },

    /**
     * Limpa as mensagens e volta a tela do pedido
     * @return {undefined}
     */
    backToOrder() {
      this.clearMessages();
      this.$router.back();
    },

    /**
     * Volta para página do produto e foca o cursor no campo do comentario
     * @return {undefined}
     */
    focusInputComment() {
      setTimeout(() => {
        document.getElementById("comment-input").focus();
      }, 800);
    },

    /**
     * Não exibe o botão se não houver texto
     * @param {object} button
     * @return {boolean}
     */
    hideButton(button) {
      if (this.isLoading) {
        return true;
      }

      return button.text === "" ? false : true;
    },

    /**
     * Valida se o valor das estrelas é maior que 0 e altera estado do botão
     * @return {undefined}
     */
    checkStarValue() {
      if (this.product.ProductComment.rating > 0) this.disableButton = false;
    },
  },

  computed: {
    ...mapState("Orders", {
      product: (state) => state.order.productRating,
      messages: (state) => state.order.messages,
    }),
    ...mapGetters(["isMobile", "langs"]),

    /**
     * Retorna diferentes classes dependendo de a página é mobile
     * @return {string}
     */
    productRatingIsMobile() {
      return this.isMobile
        ? "app__product-rating app__product-rating-isMobile"
        : "app__product-rating";
    },

    /**
     * Verifica se é a página de adicionar avaliação
     * @return {bool}
     */
    isAddPage() {
      return this.page === "add";
    },

    /**
     * Verifica se é a página de vizualizarr avaliação
     * @return {bool}
     */
    isViewPage() {
      return this.page === "view";
    },

    /**
     * Converte o valor das estrelas do comentário em numérico inteiro
     * @return {number}
     */
    ratingToInt() {
      return parseInt(this.product?.ProductComment?.rating);
    },

    /**
     * Verifica a label a ser usada ao salvar
     * @return {string}
     */
    labelForSaving() {
      if (this.isSaving) {
        return this.langs.profile.saving;
      }

      return this.primaryButton.text;
    },

    /**
     * COntrola a exibição do icone de lixeira
     * @return {bool}
     */
    showTrash() {
      if (this.isLoading) {
        return true;
      }

      return !this.isAddPage && !this.isSaving;
    },

    /**
     * COntrola o estado do botão de enviar o formulario
     * @return {bool}
     */
    disableFormButton() {
      if (this.isLoading) {
        return false;
      }

      return this.disableButton || this.isSaving;
    },
  },

  watch: {
    langs() {
      this.setPrimaryButton();
      this.setSecondaryButton();
    },
  },
};
</script>

<template>
  <section class="app" v-if="this.product.id">
    <h2 class="app__dashboard__title app__dashboard__title--2">
      {{ this.langs.product["evaluation"] }}
    </h2>
    <app-alert :messages="messages" />
    <section :class="productRatingIsMobile">
      <app-header-product :loading="isLoading" />
      <section class="app__product-rating__container">
        <div class="image-container">
          <div :class="{ app__loading: isLoading }">
            <img
              v-if="this.product?.ProductImage?.[0]?.https"
              :src="this.product?.ProductImage[0]?.https"
              :alt="product?.name"
              class="app__product-rating__image"
            />
            <app-icon
              v-else
              class="app__product-rating__image app__product-rating__image__icon"
              name="eye-slash"
            />
          </div>
          <app-product-info
            class="app__product-rating__desciption"
            :loading="isLoading"
            :product="this.product"
          />
        </div>
        <form class="app__product-rating__form" @submit.prevent="this.onSubmit">
          <div class="app__product-rating__form-trash">
            <div
              v-show="this.showTrash"
              @click="setDataModal('delete')"
              :class="{ app__loading: isLoading }"
            >
              <app-svg name="trash-alt" />
            </div>
          </div>

          <div v-show="!isViewPage" class="app__product-rating__form-starts">
            <p :class="{ app__loading: isLoading }">
              {{ this.langs.product["stars-product-deserve"] }}
            </p>
            <app-stars
              :loading="isLoading"
              :starSize="28"
              :rating="ratingToInt"
              :setRating="this.setStars"
            />
          </div>

          <div v-show="!isViewPage" class="app__product-rating__form-comment">
            <div class="header-comment">
              <p :class="{ app__loading: isLoading }">Comentario</p>
              <p :class="{ app__loading: isLoading }">
                {{ this.product?.ProductComment?.comment?.length }}/1500
              </p>
            </div>
            <textarea
              id="comment-input"
              maxlength="1500"
              placeholder="Compartilhe sua opnião sobre este produto."
              rows="4"
              :value="this.product.ProductComment.comment"
              @input="setComment"
              :class="{ app__loading: isLoading }"
            />
          </div>

          <div
            v-show="isViewPage"
            class="app__product-rating__form-starts__view-page"
          >
            <app-stars
              v-show="!isLoading"
              :readOnly="true"
              :starSize="14"
              :borderWidth="3"
              :rating="ratingToInt"
              :class="{ app__loading: isLoading }"
            />
            <p :class="{ app__loading: isLoading }">
              {{ this.product?.ProductComment?.date }}
            </p>
            <p
              class="app__product-rating__form-starts__view-page__comment"
              :class="{ app__loading: isLoading }"
            >
              {{ this.product?.ProductComment?.comment }}
            </p>
            <p :class="{ app__loading: isLoading }">
              {{ this.langs.product["name"] }}
              {{ this.product?.customer?.name }}
            </p>
          </div>

          <div class="app__product-rating__form-buttons">
            <button
              v-show="hideButton(this.secondaryButton)"
              @click="this.secondaryButton.action"
              class="app__button app__button--primary-outline app__button--no-margin"
              :class="{ app__loading: isLoading }"
              type="button"
              :disabled="this.isSaving"
            >
              {{ this.secondaryButton.text }}
            </button>
            <button
              v-show="hideButton(this.primaryButton)"
              class="app__button app__button--primary app__button--no-margin"
              :disabled="this.disableFormButton"
              :class="{ app__loading: isLoading }"
              type="submit"
            >
              {{ this.labelForSaving }}
              <app-icon
                class="app__product-rating__saving app__icon--infinite-rotate"
                prefix="fa"
                name="sync-alt"
                v-show="this.isSaving"
              >
              </app-icon>
            </button>
          </div>
        </form>
      </section>
    </section>
    <app-modal :dataModal="this.dataModal" />
  </section>
</template>
